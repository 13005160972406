
import logo from './logo.svg';
import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './navbar.js';
import HomePage from './pages/homepage.js';
import Aboutpage from './pages/aboutpage.js';
import Productpage from './pages/productpage.js';
import Cartcanvas from './components/cart.js';
import CheckoutPage from './pages/checkoutpage.js';
import SupportPage from './pages/supportpage.js';
import Footer from './components/footer.js';
import Returnpage from './pages/returnpage.js';
import Returnerrorpage from './pages/returnerrorpage.js';
import Allproductspage from './pages/allproducts.js';
import ErrorPage from './pages/notfoundpage.js';
import Adminlogin from './pages/adminlogin.js';
import Adminpanel from './pages/adminpanel.js';


// import GoToTop from './components/scrolltotop.js';

import React, { useCallback, useState, useEffect } from "react";

import GoToTop from "./components/scrolltotop.js";




function App() 
{
  
  const isAuth = () => {
    const token = localStorage.getItem("tlToken"); 
    return !!token;
  }

  return (

  
    <div className='app-background'>
      

      <Cartcanvas></Cartcanvas>
      <Navbar></Navbar>
      <GoToTop></GoToTop>
      <Routes>
        <Route path='/' element={<HomePage/>}></Route>
        <Route path='/about' element={<Aboutpage></Aboutpage>}></Route>
        <Route path='/product/:productId' element={<Productpage></Productpage>}></Route>
        <Route path='/checkout' element={<CheckoutPage></CheckoutPage>}></Route>
        <Route path='/return' element={<Returnpage></Returnpage>}></Route>
        <Route path='/returnerror' element={<Returnerrorpage></Returnerrorpage>}></Route>
        <Route path='/support' element={<SupportPage></SupportPage>}></Route>
        <Route path='/products' element={<Allproductspage></Allproductspage>}></Route>
        <Route path="*" element={<ErrorPage></ErrorPage>} />
        

        {isAuth() ? (
        <Route path="/adminpanel" element={<Adminpanel></Adminpanel>}></Route>
        ) :
        (
          <Route path="/adminpanel" element={<Adminlogin></Adminlogin>}></Route>
        )}
      </Routes>
      
      
      <Footer></Footer>    
    </div> 
    
  );
}




export default App;




