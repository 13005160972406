import React, { useEffect, useState } from "react";
import './homepage.css';
import { useNavigate } from "react-router-dom";
import HomeCarousel from "../carouselhome";
import Homeproducts from "../components/homeproducts";
import img1 from "../pictures/header.jpg";
import Productslider from "../components/productslider";


function HomePage()
{
    const [products, setProducts] =  useState([])


    const navigate = useNavigate();

    const handleNav = (to) => {
        navigate(to);
    };

    


    return(
        <div className="container-fluid">
            
            <div className="row pb-md-5 pb-1">
                <div className="col-12 p-0">
                <div id="carouselExampleIndicators" class="carousel slide carousel-height" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner" style={{height: "100%"}}>
                        <div class="carousel-item active" style={{height: "100%"}}>
                            <div className="header-slider-image-1">
                                <div className="header-button-image-1">
                                    <p className="slider-text-header">Din leverantör för heta beten</p>
                                    <p className="slider-text-body">Besök vår shop och hämta ditt nya personbästa</p>
                                    <button onClick={() => handleNav("/products")} type="button" class="btn btn-secondary" style={{fontWeight: "400"}}>Handla beten</button>
                                </div>

                            </div>
                        </div>

                            <div class="carousel-item " style={{height: "100%"}}>
                                <div className="header-slider-image-3">
                                    <div className="header-button-image-2">
                                        <p className="slider-text-header">Vilka är TLFiske?</p>
                                        <p className="slider-text-body">Läs mer om bakgrunden till Tlfiske</p>
                                        <button onClick={() => handleNav("/about")} type="button" class="btn btn-secondary" style={{fontWeight: "400"}}>Om oss</button>
                                    </div> 
                                </div>
                            </div> 

                            <div class="carousel-item " style={{height: "100%"}}>
                                <div className="header-slider-image-2">
                                <div className="header-button-image-2">
                                        <p className="slider-text-header">Behöver du komma i kontakt med oss?</p>
                                        <p className="slider-text-body">Gör den enkelt via supporten</p>
                                        <button onClick={() => handleNav("/support")} type="button" class="btn btn-secondary" style={{fontWeight: "400"}}>Kontakt</button>
                                    </div>                                     
                                </div>
                            </div> 

                        

                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>



                </div>


            </div>
            
            
            <div className="row mb-1">
                <div className="col-12 d-flex justify-content-center">
                    <p className="h1">Nytt på TLfiske</p>
                </div>
            </div>
              
            <div className="row  justify-content-center pb-md-4 pb-1">
                <div className="col-10 mb-3" style={{borderBottom: "2px solid black"}}>

                </div>
                <div className="col-md-10  col-11">
                    <Productslider key="newsslider" fetchsource="news"></Productslider>

                </div>

            </div>


            <div className="row">
                <div className="col-12 m-0 p-0" style={{height: "500px", width: "100%"}}>
                    <div className="image-holder-front">
                        <div className="image-holder-front-text-container">
                            <p className="image-holder-front-text text-center">Rätt bete lockar drömfisken. </p>
                            <p className="image-holder-front-text2 text-center"> Välj smart och varje kast blir en chans till full fångst.</p>

                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="row pt-4 mb-1">
                <div className="col-12 d-flex justify-content-center">
                    <p className="h1">Heta deals</p>
                </div>
            </div>

            <div className="row  justify-content-center pb-md-4 pb-1">
                <div className="col-10 mb-3" style={{borderBottom: "2px solid black"}}>

                </div>
                <div className="col-md-10  col-11">
                    <Productslider key="dealsslider" fetchsource="deals"></Productslider>

                </div>

            </div>
        
            
            
          



        </div>
    );
}

export default HomePage;