import "./adminpanel.css";
import { useEffect, useState } from "react";


function Adminpanel() {

    const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview
    const [image, setImage] = useState(null); // State to hold the image file

    useEffect(()=> {
        const onLoadValidation = async(token) => {
            const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/validate-token", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,   
                }, 
            });

            const data = await response.json();
            if (!data.status) {
                localStorage.clear("tlToken");
                window.location.reload();
            }

        };
        const token = localStorage.getItem("tlToken");
        if (token) {
            onLoadValidation(token);
            startExpTimer(3600000);
        } else {
            localStorage.clear("tlToken");
            window.location.reload();
        }

    }, []);

    const startExpTimer = (time) => {
        setTimeout(() => {
            alert('Sessionen är slut. Du behöver logga in igen');
            localStorage.clear("tlToken");
            window.location.reload();
        }, time);
    };
    

    const submitUpload = async(e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const price = e.target.price.value;
        const weight = e.target.weight.value;
        const length = e.target.length.value;
        const lager = e.target.lager.value;
        const color = e.target.color.value;
        const desc = e.target.desc.value;
        const artPerch = e.target.artPerch.checked;
        const artPike = e.target.artPike.checked;
        const dealsslider = e.target.dealsslider.checked;
        const newsslider = e.target.newsslider.checked;
        const art = artPerch ? "perch" : "" || artPike ? "pike" : "";
        
        
        const token = localStorage.getItem("tlToken");
        if (!token) {
            localStorage.clear("tlToken");
            window.alert("Misslyckad uppladdning! Testa logga in igen");
            window.location.reload();
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('price', price);
        formData.append('weight', weight);
        formData.append('length', length);
        formData.append('lager', lager);
        formData.append('color', color);
        formData.append('desc', desc);
        formData.append('dealsslider', dealsslider);
        formData.append('newsslider', newsslider);
        formData.append('art', art);
        if (image) {
            formData.append('image', image);
        }

        const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/upload-product", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        const result = await response.json();
        if (result.status) 
        {
            window.alert("Produkten laddades upp!");
            setImage(null);
            setImagePreview(null);
            e.target.name.value = "";
            e.target.price.value = "";
            e.target.weight.value = "";
            e.target.length.value = "";
            e.target.lager.value = "";
            e.target.color.value = "";
            e.target.image.value = "";
            e.target.desc.value = "";
            e.target.dealsslider.checked = false;
            e.target.newsslider.checked = false;
            e.target.artPerch.checked = false;
            e.target.artPike.checked = false;

        } else {
            const reason = result.reason;
            localStorage.clear("tlToken");
            window.alert(`Misslyckad uppladdning! Anledning: ${reason}. Kontakta IT-AVD <3`);
            window.location.reload();
        }
        
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
          // Check file type
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            setImage(file);
            const reader = new FileReader();
        

            reader.onload = (e) => {
              setImagePreview(e.target.result); // Set image preview URL
            };
            
            reader.readAsDataURL(file); // Read the file as a Data URL
          } else {
            alert('Please select a PNG or JPG image.');
            event.target.value = ''; // Clear the file input
          }
        }
    };








    return(
        <div className="container pb-5">
            <div className="row">
                <h1 className="admin-header-text text-center pt-5">Välkommen till TLfiske adminpanel</h1>            
            </div>
            <div className="row d-flex justify-content-center pt-5">
                <div className="col-5" style={{backgroundColor: "white", borderRadius: "8px"}}>
                    <h5 className="text-center pt-3">Ladda upp ny produkt</h5>
                    
                    <form onSubmit={e => submitUpload(e)}>
                        <label>Produktnamn</label>
                        <input id="name" className="form-control" type="text"  required/>

                        <label>Pris (standard)</label>
                        <input id="price" className="form-control" type="number"  required/>

                        <label>Bild (1080x1080, png format)</label>
                        <input id="image"  onChange={handleFileChange} className="form-control" type="file" accept="image/png, image/jpeg" required></input>
                        <div className="pt-2" style={{height: "100px"}}>
                        {imagePreview && (
                                <img
                                src={imagePreview}
                                alt="Image preview"
                                style={{height: "100%"}}
                                />
                        )}
                        </div>

                        <label>Art (för sortering)</label>
                        <div>
                            <label className="pe-3">Abborre</label>
                            <input id="artPerch" className="form-check-input" type="checkbox"></input>
                        </div>
                        <div>
                            <label className="pe-3">Gädda</label>
                            <input id="artPike" className="form-check-input" type="checkbox"></input>
                        </div>

                        <label>Optionellt (för framsidan sliders)</label>
                        <div>
                            <label className="pe-3">News slider</label>
                            <input id="newsslider" className="form-check-input" type="checkbox"></input>
                        </div>
                        <div>
                            <label className="pe-3">Deals slider</label>
                            <input id="dealsslider" className="form-check-input" type="checkbox"></input>
                        </div>


                        <label className="pt-2">Vikt i gram </label>
                        <input id="weight" className="form-control" type="number" required></input>

                        <label>Längd i cm </label>
                        <input id="length" className="form-control" type="number" required></input>

                        <label>Färg </label>
                        <input id="color" className="form-control" type="text" required></input>
                        
                        <label>Lagersaldo</label>
                        <input id="lager" className="form-control" type="number" required></input>

                        <label>Beskrivning</label>
                        <textarea id="desc" style={{height: "225px", resize: "none"}} className="form-control" type="text" required/>

                        <button className="btn btn-success w-100 my-2">Ladda upp produkt</button>
                        


                    </form>

        
                </div>
            </div>

        </div>
    );

}

export default Adminpanel;